@tailwind base;
@tailwind components;
@tailwind utilities;

.Polaris-Collapsible {
  will-change: unset !important;
}

/* Your own custom utilities */
.h-full-important {
  height: 100% !important;
}

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: block;
}

.xy-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.x-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Polaris-TopBar {
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.15);

  .Polaris-TopBar__SearchField {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .Polaris-TopBar__SecondaryMenu {
    width: 370px;
  }

  .Polaris-TopBar__LogoLink {
    font-size: 25px;
    line-height: 1;
    font-weight: 600;
    text-decoration: none;
    @apply text-primary;

    img {
      display: none;
    }

    &:after {
      content: 'Scrolly Telling'
    }
  }
}

[data-portal-id^="tooltip-Polarisportal"] {
  pointer-events: none;
}

.setting-panel .Polaris-Card .Polaris-Tabs__Panel {
  height: calc(100vh - 80px);
  overflow: hidden;
}
.setting-panel .Polaris-Card .Polaris-Tabs__Panel .Polaris-Tabs__Panel {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

#font-picker {
  width: 100%;
  box-shadow: none;
  background-color: #c4cdd5;
  border: 0.1rem solid transparent;
  border-radius: 3px;
  box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
}

#font-picker > button {
  border-radius: 3px;
  background: linear-gradient(to bottom, white, #f9fafb);
}

#font-picker .font-list {
  background-color: white;
  z-index: 50;
}